import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Task } from '../models/task.models'

export const tasksRequestListAction = createActionGroup({
  source: 'tasksGetList',
  events: {
    'Request Task List': props<{ assigned_to: number }>(),
    'Request Task List Success': props<{ tasks: Task[] }>(),
    'Request Task List Failed': (error: any) => ({ error }),
    'Request Task List Clear State': emptyProps(),
  },
})

import { createFeature, createReducer, on } from '@ngrx/store'

import { tasksRequestListAction } from './actions'
import { TaskInitialState } from '../models/task.models'

const initialTaskState: TaskInitialState = {
  isLoading: false,
  error: '',
  tasks: null,
}

const tasksGetListFeature = createFeature({
  name: 'tasksGetList',
  reducer: createReducer(
    initialTaskState,
    on(tasksRequestListAction.requestTaskList, (state) => ({ ...state, isLoading: true })),
    on(tasksRequestListAction.requestTaskListSuccess, (state, event) => ({
      ...state,
      isLoading: false,
      tasks: event.tasks,
    })),
    on(tasksRequestListAction.requestTaskListFailed, (state, event) => ({
      ...state,
      isLoading: false,
      error: event.error,
      tasks: null,
    })),
    on(tasksRequestListAction.requestTaskListClearState, (state, event) => ({
      ...initialTaskState,
    })),
  ),
})

export const {
  name: tasksGetListFeatureKey,
  reducer: tasksGetListReducer,
  selectIsLoading,
  selectError,
  selectTasks,
} = tasksGetListFeature
